<script lang="ts" setup>
import { FFmpeg } from '@ffmpeg/ffmpeg';
import type { LogEvent } from '@ffmpeg/ffmpeg/dist/esm/types';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { ref } from 'vue';
import { ReloadIcon, Cross2Icon } from '@radix-icons/vue';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Slider } from '@/components/ui/slider';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import FileUpload from '@/components/FileUpload.vue';
import Info from '@/components/Info.vue';
import { baseURL, options } from '@/config';

const ffmpeg = new FFmpeg();

const state = ref<'idle' | 'ready' | 'converting' | 'finished'>('idle');
const percentage = ref(0);
const log = ref('');
const video = ref({ url: '', filename: '', size: 0 });
const crf = ref([27]);
const crfRange = [20, 28];
const audio = ref(false);
const file = ref<File | null>(null);

const sizeInBytes = (size: number) => {
  const megabytes = Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow'
  }).format(size);

  return megabytes;
};

function handleFileChange(e: Event) {
  const target = e.target as HTMLInputElement;
  if (target.files?.length === 1) {
    file.value = target.files[0];
    state.value = 'ready';
  } else {
    file.value = null;
    state.value = 'idle';
  }
}

function reset() {
  state.value = 'ready';
  percentage.value = 0;
  log.value = '';
}

function reload() {
  window.location.reload();
}

ffmpeg.on('progress', ({ progress }) => {
  percentage.value = Math.min(Math.ceil(progress * 100), 100);
});

ffmpeg.on('log', ({ message }: LogEvent) => {
  log.value = message;
});

async function convert() {
  if (!file.value) return;

  state.value = 'converting';

  // Load FFmpeg
  log.value = 'Loading ffmpeg-core.js';

  await ffmpeg.load({
    coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
    wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript')
  });

  // Start transcoding
  log.value = 'Starting';
  const { signal } = new AbortController();

  const opts = options({
    crf: crf.value[0],
    audio: audio.value
  });

  await ffmpeg.writeFile(file.value.name, await fetchFile(file.value));
  await ffmpeg.exec(['-i', file.value.name, ...opts, 'output.mp4'], undefined, { signal });

  // Get transcoded video file
  log.value = 'Finished';
  const data = await ffmpeg.readFile('output.mp4');

  const outputVideo = new Blob([(data as Uint8Array).buffer], {
    type: 'video/mp4'
  });

  video.value.url = URL.createObjectURL(outputVideo);
  video.value.filename = file.value.name.replace(/\.[^/.]+$/, '-web.mp4');
  video.value.size = outputVideo.size;

  state.value = 'finished';
}
</script>

<template>
  <div class="w-[700px] max-w-full grid grid-cols-2 gap-6">
    <Card class="p-5 space-y-8 shadow-md">
      <div class="space-y-2">
        <Label class="block">Video</Label>
        <FileUpload
          @change="handleFileChange"
          :disabled="state === 'converting' || state === 'finished'"
        />
      </div>

      <div
        class="space-y-4"
        :class="{ 'opacity-70': state === 'converting' || state === 'finished' }"
      >
        <div class="flex justify-between">
          <Label>Bessere Qualität</Label>
          <Label>Kleinere Datei</Label>
        </div>
        <Slider
          v-model="crf"
          :min="crfRange[0]"
          :max="crfRange[1]"
          :step="1"
          :disabled="state === 'converting' || state === 'finished'"
        />
      </div>

      <div class="flex items-center space-x-2">
        <Switch
          class="data-[state=unchecked]:bg-gray-300"
          v-model:checked="audio"
          :disabled="state === 'converting' || state === 'finished'"
        />
        <Label>Video mit Ton</Label>
      </div>

      <div class="flex gap-2">
        <Button v-if="state !== 'finished'" :disabled="state !== 'ready'" @click="convert">
          <template v-if="state === 'idle' || state === 'ready'">
            <span>Video für Web konvertieren</span>
          </template>
          <template v-else-if="state === 'converting'">
            <ReloadIcon class="mr-2 h-4 w-4 animate-spin" />
            <span> Video wird konvertiert </span>
            <span className="text-slate-400">&nbsp;({{ percentage }}%)</span>
          </template>
        </Button>

        <Button v-if="state === 'converting'" variant="outline" size="icon" @click="reload">
          <Cross2Icon />
        </Button>

        <Button as-child v-if="state === 'finished'" @click="reset">
          <a :href="video.url" :download="video.filename">
            <span>Download Video</span>
            <span className="text-slate-400">&nbsp;({{ sizeInBytes(video.size) }})</span>
          </a>
        </Button>
      </div>
    </Card>

    <Card class="p-5 shadow-md">
      <Label class="block">Info</Label>
      <Info />
    </Card>

    <Card
      v-if="log"
      class="col-span-2 px-5 py-4 bg-gray-200 text-slate-600 text-[11px] font-mono leading-none shadow-inner"
    >
      {{ log }}
    </Card>
  </div>
</template>
