// const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm';
let baseURL = 'https://convert.juno-hamburg.com/ffmpeg/0.12.6';

if (import.meta.env.DEV) {
  baseURL = 'http://localhost:5173/ffmpeg/0.12.6/';
}

export { baseURL };

export const options = ({ crf, audio }: { crf: number; audio: boolean }) => {
  return [
    '-crf',
    crf.toString(),
    '-c:v',
    'libx264',
    '-x264opts',
    'colormatrix=bt709',
    '-pix_fmt',
    'yuv420p',
    '-movflags',
    '+faststart',
    '-preset',
    'slow',
    ...(audio ? ['-c:a', 'aac', '-b:a', '128k'] : ['-an'])
  ];
};
