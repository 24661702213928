<template>
  <div class="text-sm space-y-4 mt-4">
    <p>
      Das Video wird lokal in deinem Browser konvertiert, <strong>ohne Upload</strong>. Dadurch kann
      die Konvertierung selbst <strong>großer Videodateien</strong>
      (z.B. ProRes .mov) sofort gestartet werden.
    </p>
    <p>
      <strong>Maximale Pixelmaße des Videos</strong>:<br />
      16:9 → 1920&times;1080, 1:1 → 1440&times;1440
    </p>
    <p>
      Der Schieberegler für die <strong>Qualität</strong> hat den
      <strong>optimalen Wert</strong> und sollte nur bei Bedarf schrittweise angepasst werden.
    </p>
    <p>
      Während der Konvertierung kann dein Computer <strong>langsamer reagieren</strong> und die
      <strong>Lüfter</strong> können hörbar sein.
    </p>
  </div>
</template>
